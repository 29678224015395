// src/Navbar.js
import React, { useState } from 'react';
import './navbarMobile.css';
import moonnilaLogo from '../../assets/img/Moonnila.png';

const NavbarMobile = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar-mobile">
      <a href="/">
        <img src={moonnilaLogo} alt="Moonnila Logo" className="brand-logo" />
      </a>
      <div className={`hamburger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </div>
      <div className={`menu ${isOpen ? 'open' : ''}`}>
        <ul>
          <li><a href="/">Home</a></li>
          <li><a href="/beauty">Beauty</a></li>
          <li><a href="/nails">Nails</a></li>
          <li><a href="#contact">About Me</a></li>
        </ul>
      </div>
    </nav>
  );
};

export default NavbarMobile;
