import React from 'react';
import './NailCard.css';
import { useNavigate } from 'react-router-dom';

function NailCard({ title, description, price, image, route }) {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(route);
    }

    return (
        <div className="nail-card" onClick={handleClick} role="button" tabIndex="0">
            <img src={image} alt={title} className="nail-card__image" loading="lazy" />
            <div className="nail-card__content">
                <h3 className="nail-card__title">{title}</h3>
                <p className="nail-card__description">{description}</p>
                <div className="nail-card__price">CHF {price}</div>
            </div>
        </div>
    );
}

export default NailCard;
