import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#BE9F93',
        },
        secondary: {
            main: '#EBD8D0',
        },
        background: {
            default: '#FBF3EB',
        },
    },
    typography: {
        fontFamily: "'Inria Serif', serif",
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: "'Inria Serif', serif",
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#EBD8D0',
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    padding: "0 !important",
                },
            },
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: '#BE9F93',
                        color: '#FFFFFF',
                        '&:hover': {
                            backgroundColor: '#BE9F93',
                        },
                        '&:focus': {
                            backgroundColor: '#BE9F93',
                        },
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    backgroundColor: "#E0BFB9",
                    border: "none",
                    '&:hover': {
                        backgroundColor: "#D8AFAF",
                    },
                }
            }
        }
    },
});

export default theme;
