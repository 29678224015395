import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Privacy.css';

function Privacy() {
    const navigate = useNavigate();

    return (
        <div className="privacy-container">
            <button className="back-button" onClick={() => navigate(-1)}>
                ← Zurück
            </button>
            <h1>Datenschutzerklärung</h1>

            <section className="privacy-section">
                <h2>1. Einleitung</h2>
                <p>
                    Der Schutz Ihrer personenbezogenen Daten ist uns ein wichtiges Anliegen. In dieser Datenschutzerklärung informieren wir Sie über die Erhebung, Verarbeitung und Nutzung Ihrer Daten im Rahmen unserer Geschäftstätigkeit.
                </p>
            </section>

            <section className="privacy-section">
                <h2>2. Verantwortlicher</h2>
                <p>
                    <strong>Unternehmensname:</strong> Moonnila<br />
                    <strong>Inhaberin:</strong> Ajitha Thirukumar<br />
                    <strong>Adresse:</strong><br />
                    Mumenthalstrasse 35<br />
                    4912 Aarwangen<br />
                    Schweiz<br />
                    <strong>E-Mail:</strong> <a href="mailto:info@moonnila.ch">info@moonnila.ch</a><br />
                    <strong>Telefon:</strong> +41 76 480 62 30
                </p>
            </section>

            <section className="privacy-section">
                <h2>3. Erhebung und Verarbeitung personenbezogener Daten</h2>
                <p>Wir erheben und verarbeiten personenbezogene Daten in folgenden Fällen:</p>
                <ul>
                    <li>Beim Besuch unserer Website,</li>
                    <li>Bei Inanspruchnahme unserer Dienstleistungen,</li>
                    <li>Bei der Kontaktaufnahme mit uns (z.B. per E-Mail oder Telefon).</li>
                </ul>
                <p>Zu den verarbeiteten Daten gehören u.a.:</p>
                <ul>
                    <li>Name,</li>
                    <li>Adresse,</li>
                    <li>E-Mail-Adresse,</li>
                    <li>Telefonnummer,</li>
                    <li>Zahlungsinformationen,</li>
                    <li>IP-Adresse.</li>
                </ul>
            </section>

            <section className="privacy-section">
                <h2>4. Zwecke der Datenverarbeitung</h2>
                <p>Die Verarbeitung Ihrer personenbezogenen Daten erfolgt zu folgenden Zwecken:</p>
                <ul>
                    <li>Zur Erfüllung von Verträgen und Bearbeitung Ihrer Bestellungen,</li>
                    <li>Zur Kommunikation mit Ihnen,</li>
                    <li>Zur Verbesserung unserer Dienstleistungen und Angebote,</li>
                    <li>Zur Einhaltung rechtlicher Verpflichtungen.</li>
                </ul>
            </section>

            <section className="privacy-section">
                <h2>5. Rechtsgrundlage der Verarbeitung</h2>
                <p>Die Verarbeitung Ihrer Daten basiert auf folgenden Rechtsgrundlagen:</p>
                <ul>
                    <li>Einwilligung (Art. 6 Abs. 1 lit. a DSGVO),</li>
                    <li>Vertragserfüllung (Art. 6 Abs. 1 lit. b DSGVO),</li>
                    <li>rechtliche Verpflichtungen (Art. 6 Abs. 1 lit. c DSGVO).</li>
                </ul>
            </section>

            <section className="privacy-section">
                <h2>6. Weitergabe von Daten</h2>
                <p>
                    Eine Weitergabe Ihrer personenbezogenen Daten an Dritte erfolgt nur, wenn dies zur Vertragserfüllung erforderlich ist oder Sie zuvor zugestimmt haben. In bestimmten Fällen werden Ihre Daten an Dienstleister (z.B. Versand- oder Zahlungsdienstleister) weitergegeben, die uns bei der Erbringung unserer Dienstleistungen unterstützen.
                </p>
            </section>

            <section className="privacy-section">
                <h2>7. Datensicherheit</h2>
                <p>
                    Wir setzen geeignete technische und organisatorische Massnahmen ein, um Ihre personenbezogenen Daten vor Verlust, Missbrauch und unbefugtem Zugriff zu schützen.
                </p>
            </section>

            <section className="privacy-section">
                <h2>8. Ihre Rechte</h2>
                <p>Sie haben das Recht:</p>
                <ul>
                    <li>Auskunft über die von uns gespeicherten personenbezogenen Daten zu erhalten,</li>
                    <li>die Berichtigung unrichtiger oder unvollständiger Daten zu verlangen,</li>
                    <li>die Löschung Ihrer personenbezogenen Daten zu beantragen,</li>
                    <li>die Einschränkung der Datenverarbeitung zu verlangen,</li>
                    <li>Ihre Einwilligung zur Datenverarbeitung jederzeit zu widerrufen,</li>
                    <li>Beschwerde bei der zuständigen Aufsichtsbehörde einzureichen.</li>
                </ul>
            </section>

            <section className="privacy-section">
                <h2>9. Änderungen dieser Datenschutzerklärung</h2>
                <p>
                    Wir behalten uns das Recht vor, diese Datenschutzerklärung bei Bedarf zu aktualisieren. Die jeweils aktuelle Version wird auf unserer Website veröffentlicht.
                </p>
            </section>

            <section className="privacy-section">
                <h2>10. Kontakt</h2>
                <p>
                    Bei Fragen zur Verarbeitung Ihrer personenbezogenen Daten oder zur Wahrnehmung Ihrer Rechte wenden Sie sich bitte an uns:<br />
                    <strong>E-Mail:</strong> <a href="mailto:info@moonnila.ch">info@moonnila.ch</a><br />
                    <strong>Telefon:</strong> +41 76 480 62 30
                </p>
            </section>
        </div>
    );
}

export default Privacy;
