import './Home.css';
import ImageCards from '../../components/home/imageCards/ImageCards';
import AboutMe from '../../components/home/aboutMe/AboutMe';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

function Home() {
    const location = useLocation();

    useEffect(() => {
        if (location.state?.scrollToAboutMe) {
            const aboutMeSection = document.getElementById('about-me');
            if (aboutMeSection) {
                aboutMeSection.scrollIntoView({ behavior: 'smooth'});
            }
        }
    })

    return (
        <div className='home'>
            <ImageCards />
            <AboutMe id="about-me" />
        </div>
    );
}

export default Home;