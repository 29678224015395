import React from 'react';
import './footer.css';
import instagram from '../../assets/img/Instagram.png';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer-left'>
        <a href='https://www.instagram.com/moonnilabeauty' target='_blank' rel='noopener noreferrer' className='instagram-wrapper'>
          <img src={instagram} alt="Instagram Moonnila Beauty" />
          <p>Moonnila Beauty</p>
        </a>
        <a href='https://www.instagram.com/moonnilanails' target='_blank' rel='noopener noreferrer' className='instagram-wrapper'>
          <img src={instagram} alt="Instagram Moonnila Nails" />
          <p>Moonnila Nails</p>
        </a>
      </div>
      <div className='footer-center'>
        <p>&copy; <a href='https://polyapps.ch' className='polyApps'>
          {new Date().getFullYear()} polyApps</a>
        </p>
      </div>
      <div className='footer-right'>
        <a href='/privacy' className='footer-link'>Datenschutz</a>
        <span> | </span>
        <a href='/imprint' className='footer-link'>Impressum</a>
      </div>
    </footer>
  );
};

export default Footer;
