import React from 'react';
import './BeautyImageSection.css';
import { Button } from '@mui/material';

const BeautyImageSection = ({ title, text, imageSrc, isImageLeft, onRequest }) => {
    const containerClass = isImageLeft ? 'image-section image-left' : 'image-section image-right';

    return (
        <div className={containerClass}>
            <div className="content-section">
                <h2>{title}</h2>
                <p>{text}</p>
                <Button onClick={onRequest} color="primary" variant="contained" sx={{fontSize: {
                    xs: '0.9rem',
                    sm: '1rem',
                    md: '1.2rem',
                },}}>
                    Anfragen
                </Button>
            </div>
            <div className="image-section__image">
                <img src={imageSrc} alt={title} loading="lazy" />
            </div>
        </div>
    );
};

export default BeautyImageSection;
