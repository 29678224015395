import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  useMediaQuery,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import PhoneNumberField from "../../form/PhoneNumberField";
import EmailField from "../../form/EmailField";
import TextInputField from "../../form/TextInputField";
import DateField from "../../form/DateField";
import TextAreaField from "../../form/TextAreaField";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@emotion/react";
import { RequestService } from "../../../services/RequestService";
import TimeRangeField from "../../form/TimeRangeField";
import dayjs from "dayjs";

const RequestDialog = ({ open, onClose, initialService }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const initialFormValues = {
    name: "",
    email: "",
    phone: "",
    eventType: "",
    eventDate: "",
    eventTimeFrom: null,
    eventTimeTo: null,
    eventLocation: "",
    service: initialService || "",
    remarks: "",
  };

  const [formValues, setFormValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleChange = ({ target: { name, value } }) => {
    setFormValues((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));

    if (name === "eventTimeFrom" || name === "eventTimeTo") {
      validateTimeRange(
        name === "eventTimeFrom" ? value : formValues.eventTimeFrom,
        name === "eventTimeTo" ? value : formValues.eventTimeTo
      );
    }
  };

  const handleBlur = ({ target: { name, value } }) => {
    if (!value && name !== "remarks") {
      setErrors((prev) => ({ ...prev, [name]: "Pflichtfeld" }));
    }
  };

  const validateTimeRange = (from, to) => {
    let error = "";
    if (from && to) {
      if (dayjs(to, "HH:mm").isBefore(dayjs(from, "HH:mm"))) {
        error = "Endzeit darf nicht vor der Startzeit liegen";
      }
    }
    setErrors((prev) => ({ ...prev, eventTimeFrom: error, eventTimeTo: error }));
  };

  const handleSubmit = async () => {
    const newErrors = {};
    let isValid = true;

    Object.keys(formValues).forEach((field) => {
      if ((field === "eventTimeFrom" || field === "eventTimeTo") && !formValues[field]) {
        newErrors.eventTimeFrom = "Pflichtfeld";
        newErrors.eventTimeTo = "Pflichtfeld";
        isValid = false;
      } else if (!formValues[field] && field !== "remarks") {
        newErrors[field] = "Pflichtfeld";
        isValid = false;
      }
    });

    setErrors(newErrors);

    if (isValid) {
      try {
        await RequestService.sendRequest(formValues);
        setSnackbarOpen(true);
        handleClose();
      } catch (error) {
        console.error("Error submitting request:", error);
      }
    }
  };

  const handleClose = () => {
    setFormValues(initialFormValues);
    setErrors({});
    onClose();
  };

  const handleSnackbarClose = () => setSnackbarOpen(false);

  useEffect(() => {
    if (initialService) {
      setFormValues((prev) => ({ ...prev, service: initialService }));
    }
  }, [initialService]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth fullScreen={fullScreen}>
        <DialogTitle component="div" sx={{ m: 0, p: 2, position: "relative" }}>
          <h1>Offerte anfragen</h1>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <h3>Kontaktdaten</h3>
          <Grid container spacing={2}>
            <Grid size={12}>
              <TextInputField
                label="Name"
                name="name"
                placeholder="Vorname Name"
                value={formValues.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.name}
                helperText={errors.name}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <EmailField
                value={formValues.email}
                onChange={(email) => setFormValues((prev) => ({ ...prev, email }))}
                onBlur={(hasError) => {
                  const error = !formValues.email
                    ? "Pflichtfeld"
                    : hasError
                    ? "Ungültige E-Mail-Adresse"
                    : "";
                  setErrors((prev) => ({ ...prev, email: error }));
                }}
                error={!!errors.email}
                helperText={errors.email}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <PhoneNumberField
                value={formValues.phone}
                onChange={(phone) => setFormValues((prev) => ({ ...prev, phone }))}
                onBlur={(hasError) => {
                  const error = !formValues.phone
                    ? "Pflichtfeld"
                    : hasError
                    ? "Ungültige Telefonnummer"
                    : "";
                  setErrors((prev) => ({ ...prev, phone: error }));
                }}
                error={!!errors.phone}
                helperText={errors.phone}
              />
            </Grid>
          </Grid>
          <h3>Eventdaten</h3>
          <Grid container spacing={2}>
            <Grid size={12}>
              <TextInputField
                label="Eventart"
                name="eventType"
                value={formValues.eventType}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.eventType}
                helperText={errors.eventType}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <DateField
                label="Datum"
                name="eventDate"
                value={formValues.eventDate}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.eventDate}
                helperText={errors.eventDate}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TimeRangeField
                value={{ eventTimeFrom: formValues.eventTimeFrom, eventTimeTo: formValues.eventTimeTo }}
                nameFrom="eventTimeFrom"
                nameTo="eventTimeTo"
                onChange={handleChange}
                onBlur={handleBlur}
                errorFrom={!!errors.eventTimeFrom}
                errorTo={!!errors.eventTimeTo}
                helperTextFrom={errors.eventTimeFrom}
                helperTextTo={errors.eventTimeTo}
              />
            </Grid>
            <Grid size={12}>
              <TextInputField
                label="Ort"
                name="eventLocation"
                value={formValues.eventLocation}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.eventLocation}
                helperText={errors.eventLocation}
              />
            </Grid>
            <Grid size={12}>
              <TextInputField
                label="Dienstleistung"
                name="service"
                value={formValues.service}
                disabled
              />
            </Grid>
            <Grid size={12}>
              <TextAreaField
                label="Bemerkung (optional)"
                name="remarks"
                value={formValues.remarks}
                onChange={handleChange}
                multiline
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            sx={{ marginRight: "1rem", marginBottom: "1rem" }}
          >
            Senden
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={10000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Vielen Dank für Ihre Anfrage. Wir werden uns innerhalb von 24 Stunden bei Ihnen melden.
        </Alert>
      </Snackbar>
    </>
  );
};

export default RequestDialog;
