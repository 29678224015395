import React from "react";
import { useParams } from "react-router-dom";

function NailDetail() {
    const { nailType } = useParams();

    return (
        <div>
            <h1>Details for {nailType.replace("-", " ")}</h1>
        </div>
    );
}

export default NailDetail;
