import NailCard from "../../components/nails/nailCard/NailCard";
import PlainColorImage from "../../assets/img/nails/PlainColorImg.jpeg";
import MultipleColorsImage from "../../assets/img/nails/MultipleColorImg.jpg";
import BasicCustomImgage from "../../assets/img/nails/BasicCustomImg.jpg";
import "./Nails.css";
import GoogleMaps from "../../components/nails/googleMap/GoogleMaps";

function Nails() {
    return (
        <div className="nails">
            <div className="nail-card-gallery">
                <NailCard
                    title="PLAIN COLOUR"
                    description="Dieses Set enthält nur eine Farbe."
                    price="45"
                    image={PlainColorImage}
                    route="/nails/plain-colour"
                />
                <NailCard
                    title="MULTIPLE COLOURS"
                    description="Dieses Set besteht aus zwei unterschiedlichen Farben."
                    price="55"
                    image={MultipleColorsImage}
                    route="/nails/multiple-colours"
                />
                <NailCard
                    title="BASIC CUSTOM DESIGN"
                    description="Dieses Set ist eine Kombination aus zwei Farben, die miteinander vermischt werden."
                    price="60"
                    image={BasicCustomImgage}
                    route="/nails/basic-custom-design"
                />
            </div>
            <GoogleMaps />
        </div>
    )
}

export default Nails;