// Import the necessary Firebase SDK modules
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Firebase project configuration object
const firebaseConfig = {
    apiKey: "AIzaSyDd_KteTJFHqQtbj7QVRTZmmEYWX4mZC5E",
    authDomain: "moonnila-ee493.firebaseapp.com",
    projectId: "moonnila-ee493",
    storageBucket: "moonnila-ee493.firebasestorage.app",
    messagingSenderId: "350523026638",
    appId: "1:350523026638:web:7b705a09788a9b7b42b49f"
};

// Initialize Firebase app
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(firebaseApp);

// Export Firestore
export { db };
