import React from "react";
import { TextField } from "@mui/material";

const TextInputField = ({
    label,
    name,
    placeholder,
    value,
    onChange,
    onBlur,
    error,
    helperText,
    required = false,
    type = "text",
    fullWidth = true,
    disabled = false,
    slotProps = {},
}) => {
    return (
        <TextField
            label={label}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            fullWidth={fullWidth}
            required={required}
            error={!!error}
            helperText={helperText}
            type={type}
            autoComplete="off"
            disabled={disabled}
            slotProps={{
                ...slotProps,
                inputLabel: {
                    ...slotProps.inputLabel,
                    required,
                }
            }}
        />
    );
};

export default TextInputField;
