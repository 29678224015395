import { db } from "./firebaseConfig";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

const sendRequest = async (requestData) => {
  try {
    const requestsCollectionRef = collection(db, "requests");
    const requestWithTimestamp = {
      ...requestData,
      status: 'angefragt',
      createdAt: serverTimestamp(),
    };
    await addDoc(requestsCollectionRef, requestWithTimestamp);
  } catch (error) {
    throw error;
  }
};

export const RequestService = {
  sendRequest,
};
