import React from 'react';
import beauty from './../../../assets/img/Beauty.png';
import nails from './../../../assets/img/Nails.png';
import './ImageCards.css';
import { Link } from 'react-router-dom';

const ImageCards = () => {
    return (
        <div className='image-container'>
            <Link to='/beauty' className='image-card'>
                <img src={beauty} alt="Moonnila Beauty" className='card-image' loading="lazy" />
                <div className='card-overlay'>
                    <span className='card-text'>BEAUTY</span>
                </div>
            </Link>
            <Link to='/nails' className='image-card'>
                <img src={nails} alt="Moonnila Nails" className='card-image' loading="lazy" />
                <div className='card-overlay'>
                    <span className='card-text'>NAILS</span>
                </div>
            </Link>
        </div>
    );
}

export default ImageCards;
