import React from 'react';
import './BeautyTextSection.css';
import { Button } from '@mui/material';

const BeautyTextSection = ({ title, text, onRequest }) => {
    return (
        <div className="text-section">
            <h2>{title}</h2>
            <p>{text}</p>
            <Button onClick={onRequest} color="primary" variant="contained"  sx={{fontSize: {
                xs: '0.9rem', // Font size for extra-small screens
                sm: '1rem',   // Font size for small screens
                md: '1.2rem', // Font size for medium and larger screens
            },}}>
                Anfragen
            </Button>
        </div>
    );
};

export default BeautyTextSection;