import React from 'react';
import { TextField, FormHelperText, Box } from '@mui/material';

const TextAreaField = ({
    label,
    name,
    value,
    onChange,
    onBlur,
    error,
}) => {
    const maxLength = 255;

    return (
        <Box position="relative">
            <TextField
                label={label}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error}
                multiline
                rows={4}
                fullWidth
                inputProps={{ maxLength }}
            />
            <FormHelperText
                error={error}
                style={{
                    position: 'absolute',
                    right: 0,
                    bottom: '-1.5em',
                }}
            >
                {`${value.length}/${maxLength}`}
            </FormHelperText>
        </Box>
    );
};

export default TextAreaField;
