import React from 'react';
import './AboutMe.css';
import AboutMeImg from '../../../assets/img/AboutMe.jpg';

const AboutMe = () => {
    return (
        <div id="about-me" className="about-me">
            <div className="about-me__image">
                <img src={AboutMeImg} alt="About Me" loading="lazy" />
            </div>
            <div className="about-me__text">
                <p>Hello my dear</p>
                <p>Ich bin <b>Ajitha</b> – Freunde beschreiben mich als hilfsbereit, unterstützend und perfektionistisch. Schon früh habe ich meine kulturellen Wurzeln schätzen gelernt und bis heute verfolge ich sie voller Leidenschaft. Es ist mir eine Freude, dich an deinem besonderen Tag zu begleiten und Teil deines besonderen Moments zu sein.</p>
                <p>Bei <b>Moonnila</b> dreht sich alles darum, Menschen durch die Kunst des Make-ups zu empowern und sie selbstbewusst und strahlend fühlen zu lassen. <b>Make-up ist für mich mehr als nur Kosmetik</b> – es ist eine Möglichkeit, Persönlichkeit und natürliche Schönheit zum Ausdruck zu bringen.</p>
                <p>Ich kreiere individuelle Looks, die deine natürliche Schönheit betonen und dich zur besten Version deiner selbst machen. Von <b>Make-up bis Nageldesign</b> – ob schlicht oder extravagant – ich kümmere mich darum, dass du an deinem besonderen Tag nichts weniger als aussergewöhnlich aussiehst!</p>
            </div>
        </div>
    );
}

export default AboutMe;
