import React, { useState } from "react";
import { TextField } from "@mui/material";

const EmailField = ({ value, onChange, onBlur, error, helperText }) => {
    const [localError, setLocalError] = useState("");

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleChange = (event) => {
        const email = event.target.value;
        onChange(email);
        if (localError) {
            setLocalError("");
        }
    };

    const handleBlur = () => {
        if (!value) {
            setLocalError("Pflichtfeld");
        } else if (!validateEmail(value)) {
            setLocalError("Ungültige E-Mail-Adresse");
        } else {
            setLocalError("");
            onBlur && onBlur(false); // Remove the error if valid
            return;
        }
        onBlur && onBlur(true); // Indicate error to the parent
    };

    return (
        <TextField
            label="E-Mail"
            name="email"
            type="email"
            placeholder="info@moonnila.ch"
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            required
            autoComplete="off"
            error={!!localError || error}
            helperText={localError || helperText}
            InputLabelProps={{ required: false }}
        />
    );
};

export default EmailField;
