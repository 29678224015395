import React from "react";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/de";

const DateField = ({ label, name, value, onChange, onBlur, error, helperText }) => {
  const minDate = dayjs().add(1, "day");
  let hasSelectedDate = false;

  const handleChange = (newValue) => {
    const isValidDate = newValue && newValue.isValid();
    hasSelectedDate = isValidDate;

    onChange({
      target: {
        name,
        value: isValidDate ? newValue.format("YYYY-MM-DD") : "",
      },
    });
  };

  const handleClose = () => {
    if (!hasSelectedDate && !value) {
      onBlur({ target: { name, value: "" } });
    }
    hasSelectedDate = false;
  };

  const handleBlur = () => {
    const formattedValue = value ? dayjs(value).format("YYYY-MM-DD") : "";
    onBlur({ target: { name, value: formattedValue } });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
      <DesktopDatePicker
        label={label}
        value={value ? dayjs(value) : null}
        onChange={handleChange}
        minDate={minDate}
        format="DD.MM.YYYY"
        onClose={handleClose}
        slotProps={{
          textField: {
            name,
            error: error,
            helperText: helperText || (error ? "Ungültiges Datum" : ""),
            fullWidth: true,
            required: true,
            onBlur: handleBlur,
            InputLabelProps: { required: false },
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default DateField;
