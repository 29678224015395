import React from 'react';
import './navbar.css';
import moonnilaLogo from '../../assets/img/Moonnila.png';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
    const navigate = useNavigate();

    return (
        <nav className="navbar">
            <ul className="nav-list">
                <li className="nav-item" onClick={() => navigate('/')}>HOME</li>
                <li className="nav-item" onClick={() => navigate('/beauty')}>BEAUTY</li>
            </ul>
            <a href="/">
                <img src={moonnilaLogo} alt="Moonnila Logo" className="brand-logo" />
            </a>
            <ul className="nav-list">
                <li className="nav-item" onClick={() => navigate('/nails')}>NAILS</li>
                <li className="nav-item" onClick={() => navigate('/', { state: { scrollToAboutMe: true } })}>ÜBER MICH</li>
            </ul>
        </nav>
    );
}

export default Navbar