import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Imprint.css';

function Imprint() {
    const navigate = useNavigate();

    return (
        <div className="imprint-container">
            <button className="back-button" onClick={() => navigate(-1)}>
                ← Zurück
            </button>
            <h1 className="imprint-heading">Impressum</h1>

            <div className="imprint-content">
                <div className="imprint-section">
                    <strong>Unternehmensname:</strong>
                    <span>Moonnila Beauty & Nails</span>
                </div>

                <div className="imprint-section">
                    <strong>Inhaber:</strong>
                    <span>Ajitha Thirukumar</span>
                </div>

                <div className="imprint-section">
                    <strong>Anschrift:</strong>
                    <span>Mumenthalstrasse 35</span>
                    <span>4912 Aarwangen</span>
                    <span>Schweiz</span>
                </div>

                <div className="imprint-section">
                    <strong>Kontakt:</strong>
                    <span>Telefon: +41 76 480 62 30</span>
                    <span>E-Mail: <a href="mailto:info@moonnila.ch">info@moonnila.ch</a></span>
                    <span>Webseite: <a href="http://www.moonnila.ch" target="_blank" rel="noopener noreferrer">www.moonnila.ch</a></span>
                </div>
            </div>
        </div>
    );
}

export default Imprint;
