import React, { useState } from "react";
import { TextField } from "@mui/material";

// Function to format a Swiss phone number
const formatSwissPhoneNumber = (number) => {
    const cleaned = number.replace(/\s/g, "");
    if (cleaned.length <= 3) {
        return cleaned;
    } else if (cleaned.length <= 6) {
        return `${cleaned.slice(0, 3)} ${cleaned.slice(3)}`;
    } else if (cleaned.length <= 8) {
        return `${cleaned.slice(0, 3)} ${cleaned.slice(3, 6)} ${cleaned.slice(6)}`;
    } else {
        return `${cleaned.slice(0, 3)} ${cleaned.slice(3, 6)} ${cleaned.slice(6, 8)} ${cleaned.slice(8, 10)}`;
    }
};

// Function to validate a Swiss phone number
const isValidSwissPhoneNumber = (number) => {
    const cleaned = number.replace(/\s/g, "");
    const swissPhonePattern = /^0[1-9]\d{8}$/;
    return swissPhonePattern.test(cleaned);
};

const PhoneNumberField = ({ value, onChange, onBlur, error, helperText }) => {
    const [localError, setLocalError] = useState("");

    const handleChange = (event) => {
        const rawValue = event.target.value.replace(/\s/g, "");
        if (rawValue.length <= 10 && /^[0-9]*$/.test(rawValue)) {
            const formattedValue = formatSwissPhoneNumber(rawValue);
            onChange(formattedValue);
        }
        if (localError) {
            setLocalError("");
        }
    };

    const handleBlur = () => {
        const cleanedValue = value.replace(/\s/g, "");
        let errorMessage = "";

        if (!cleanedValue) {
            errorMessage = "Pflichtfeld";
        } else if (!isValidSwissPhoneNumber(cleanedValue)) {
            errorMessage = "Ungültige Telefonnummer";
        }

        setLocalError(errorMessage);

        // Notify parent about the error state
        onBlur && onBlur(!!errorMessage);
    };

    return (
        <TextField
            label="Telefonnummer"
            name="phone"
            placeholder="076 480 62 30"
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            required
            error={!!localError || error}
            helperText={localError || helperText}
            autoComplete="off"
            inputProps={{ maxLength: 13 }}
            InputLabelProps={{ required: false }}
        />
    );
};

export default PhoneNumberField;
