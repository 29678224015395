import React from 'react';
import { Box } from '@mui/material';
import { deDE } from '@mui/x-date-pickers/locales';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/de';

const TimeRangeField = ({
  value,
  onChange,
  onBlur,
  errorFrom,
  errorTo,
  helperTextFrom,
  helperTextTo,
}) => {
  const { eventTimeFrom, eventTimeTo } = value;

  const handleTimeChange = (field) => (newValue) => {
    const formattedTime = newValue ? newValue.format('HH:mm') : '';
    onChange({
      target: {
        name: field,
        value: formattedTime,
      },
    });
  };

  const handleBlurFrom = () => {
    onBlur({ target: { name: 'eventTimeFrom', value: eventTimeFrom } });
  };

  const handleBlurTo = () => {
    onBlur({ target: { name: 'eventTimeTo', value: eventTimeTo } });
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="de"
      localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" gap={2}>
          <TimePicker
            label="Von"
            value={eventTimeFrom ? dayjs(eventTimeFrom, 'HH:mm') : null}
            onChange={handleTimeChange('eventTimeFrom')}
            slotProps={{
              textField: {
                name: 'eventTimeFrom',
                error: errorFrom,
                helperText: helperTextFrom,
                fullWidth: true,
                required: true,
                onBlur: handleBlurFrom,
              },
              actionBar: {
                sx: {
                  '& .MuiButton-root': {
                    color: 'black',
                    textDecoration: 'none',
                  },
                },
              },
            }}
          />
          <TimePicker
            label="Bis"
            value={eventTimeTo ? dayjs(eventTimeTo, 'HH:mm') : null}
            onChange={handleTimeChange('eventTimeTo')}
            slotProps={{
              textField: {
                name: 'eventTimeTo',
                error: errorTo,
                helperText: helperTextTo,
                fullWidth: true,
                required: true,
                onBlur: handleBlurTo,
              },
              actionBar: {
                sx: {
                  '& .MuiButton-root': {
                    color: 'black',
                    textDecoration: 'none',
                  },
                },
              },
            }}
          />
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default TimeRangeField;
